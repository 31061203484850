<template>
  <div class="legal_statement">
    <Navbar />
    <div class="content">
      <h3
        v-html="data.titleKA"
        v-if="lang == 'ka'"
        style="text-transform: uppercase"
        class="mb-5 wordBreaking"
      ></h3>
      <h3
        v-else
        v-html="data.titleEN"
        style="text-transform: uppercase"
        class="mb-5"
      ></h3>
      <div>
        <p v-if="lang == 'ka'" v-html="data.descriptionKA">
          {{ data.descriptionKA }}
        </p>
        <p v-else v-html="data.descriptionEN">{{ data.descriptionEN }}</p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../components/Footer/newFooter/Footer.vue";
import axios from "axios";
import env from "../../env.json";
export default {
  components: {
    Footer,
    Navbar,
  },
  data() {
    return {
      data: {},
      lang: "ka",
    };
  },
  mounted() {
    this.isLoading = true;
    axios.get(`${env.host}/get/informations/legal`).then((result) => {
      this.data = result.data.item;
      this.isLoading = false;
      console.log(this.items);
    });
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
};
</script>

<style scoped>
.wordBreaking {
  word-break: break-word;
}
.content {
  margin: auto;
  width: 100%;
  max-width: 1400px;
  min-height: calc(100vh - 190px);
  padding: 50px 0 50px 0;
}
h3 {
  color: #7a5691;
}
h5,
p {
  color: #636363;
}
@media all and (max-width: 1500px) {
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
